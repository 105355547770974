<div class="generative-observation-section">
  <div class="label">Nitel IQ Observation</div>
  <div class="observation-container">
    <div class="robot-photo-section">
      <span><img alt="robot icon" src="assets/images/robot-solid.svg"/></span>
    </div>
    <mat-progress-bar class="buff-bar" mode="buffer" *ngIf="isLoading"></mat-progress-bar>
    <div class="response-section">
      <div class="iq-response-section">
        <mat-progress-bar class="loading-bar" mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
        <ng-container *ngIf="isDataLoaded">
          <span *ngIf="summary; else noSummary">
            {{ summary }}
          </span>
          <ng-template #noSummary>
            <span>No summary available.</span>
          </ng-template>
        </ng-container>
        <ng-template #noData>
          <span *ngIf="!isDataLoaded">No data available from the server.</span>
        </ng-template>
      </div>
    </div>
  </div>
</div>
