export interface Navigation {
  link: string;
  showOverlay?: boolean;
  name: string;
  exact: boolean;
  permissions: string;
}

export const nav: Navigation[] = [
  {
    link: '/',
    name: 'Home',
    exact: true,
    permissions: 'Dashboard'
  },
  {
    link: '/billing',
    name: 'Billing',
    exact: true,
    permissions: 'Invoice and Billing: Read'
  }
];
export const nav2: Navigation[] = [
  {
    link: '/orders',
    name: 'Orders',
    exact: true,
    permissions: 'Orders'
  },
  {
    link: '/orders-dashboard',
    name: 'Orders Dashboard',
    exact: true,
    permissions: 'Orders'
  },
  {
    link: '/services',
    name: 'Services',
    exact: true,
    permissions: 'Services'
  },
  {
    link: '/tickets',
    name: 'Tickets',
    exact: true,
    permissions: 'Ticketing: Read'
  },
];

