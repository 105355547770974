<header class="static-header" *ngIf="header.visible">
  <a routerLink="/">
    <div class="header-logo"></div>
  </a>

  <nav class="header-nav">
    <ul class="menu-items">
      <li class="nav-item" *ngFor="let item of nav">
        <ng-template [ngxPermissionsOnly]="item.permissions">
          <a
            *ngIf="!item.showOverlay"
            class="label"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: item.exact }"
            routerLink="{{ item.link }}"
            >{{ item.name }}</a
          >
        </ng-template>
      </li>
      <ng-template [ngxPermissionsOnly]="'Monitoring'">
        <li
          class="nav-item"
          *ngIf="networkUrl || hasMonitoringLink"
          [matMenuTriggerFor]="monitoringMenu"
        >
          <span>Monitoring</span>
        </li>
        <mat-menu #monitoringMenu="matMenu" xPosition="after">
          <a
            mat-menu-item
            *ngFor="let link of sortedMonitoringList"
            [attr.href]="link.URL__c"
            target="_blank"
            >{{ link.Name }}</a
          >
        </mat-menu>
      </ng-template>
      <li class="nav-item" *ngFor="let item of nav2">
        <ng-template [ngxPermissionsOnly]="item.permissions">
          <a
            *ngIf="!item.showOverlay"
            class="label"
            routerLinkActive="active-link"
            [routerLinkActiveOptions]="{ exact: item.exact }"
            routerLink="{{ item.link }}"
            >{{ item.name }}</a
          >
        </ng-template>
      </li>

      <li class="nav-item logout" (click)="logOut()">
        <span class="label">Sign Out</span>
      </li>

      <app-account-menu></app-account-menu>
    </ul>
  </nav>
</header>
